import * as React from "react"
import {navigate} from "gatsby"

import IMG_FORGOT_PASSWORD from "../images/img-forgot-password.svg"
import {Spacing} from "../components/base/spacing"
import {TextField} from "../components/base/text-field"
import {Button} from "../components/base/button"
import {useInput} from "../hooks/inputs"
import {emailValidator} from "../lib/validator/email"
import {useMutation} from "../hooks/react-query/useMutationV2"
import {useSignInWorkspace, useSearchParams} from "../hooks/gatsby-page"

import AuthLayout from "../layouts/auth-layout"

const newPasswordValidator = ({text: password = ""}) => {
  if (password.length < 8) {
    return "The password field must be at least 8 characters."
  }
  return undefined
}

const confirmPasswordValidator = ({text: password, newPassword}) => {
  if (password !== newPassword) {
    return "Passwords do not match."
  }
  return undefined
}

const workspaceRunError = ({error, isLoading}) => {
  if (!error || isLoading) return undefined
  if (!error.code) return "workspace is not valid"
  return undefined
}

const lazyLoadResetPassword = async (...args) => {
  const modules = await Promise.all([
    import("../lib/aws/configure"),
    import("../lib/smartworks-api/auth"),
  ])

  modules[0].configureAmplify()
  return modules[1].resetPassword(...args)
}

/**
 * @typedef {import('./signin').PageProps} PageProps
 */

/**
 * @type {React.FunctionComponent<PageProps>}
 * @param {PageProps} props
 */
const ResetPasswordPage = props => {
  // get workspace string from url params or location state as default value
  const workspace = useSignInWorkspace(props)
  const params = useSearchParams(props)

  const codeInput = useInput({
    defaultValue: params.get("code") || params.get("verification-code") || "",
  })
  const workspaceInput = useInput({
    defaultValue: workspace,
  })
  const emailInput = useInput({
    defaultValue: props.location.state?.email || "",
    validator: emailValidator,
  })
  const newPasswordInput = useInput({
    defaultValue: "",
    validator: newPasswordValidator,
  })

  const confirmPasswordInput = useInput({
    defaultValue: "",
    validator: confirmPasswordValidator,
    shouldDisplayError: ({text, hasBlurred}) => hasBlurred || !!text,
    validatorProps: {
      newPassword: newPasswordInput.value,
    },
  })

  // reset password hook
  const [runResetPassword, {isLoading, error}] = useMutation(
    lazyLoadResetPassword,
    {
      onSuccess: (_data, variables) => {
        navigate(`../password-changed`, {
          state: variables,
        })
      },
    },
  )

  const invalidInputs =
    !codeInput.value ||
    emailInput.error ||
    !workspaceInput.value ||
    newPasswordInput.error ||
    confirmPasswordInput.error

  const onSubmitHandler = e => {
    e.preventDefault && e.preventDefault()
    if (invalidInputs) return
    runResetPassword({
      workspace: workspaceInput.value,
      email: emailInput.value,
      verificationCode: codeInput.value,
      password: confirmPasswordInput.value,
    })
  }

  return (
    <AuthLayout bannerImg={IMG_FORGOT_PASSWORD} seoTitle="Reset Password">
      <h1>Check your email to reset</h1>
      <Spacing spacing="m" />
      <p>
        In order to further verify your identity, enter the verification code
        that was sent to your email.
      </p>
      <Spacing spacing="none" height={70} />

      <form onSubmit={onSubmitHandler}>
        <TextField
          {...codeInput.getProps({
            "aria-label": "Verification Code",
            tabIndex: "0",
            disabled: isLoading,
            error: error && !isLoading ? error.message : undefined,
          })}
        />
        <Spacing spacing="xxl" />
        <TextField
          {...workspaceInput.getProps({
            "aria-label": "Workspace Name",
            tabIndex: "0",
            disabled: isLoading,
            error: workspaceRunError({error, isLoading}),
          })}
        />
        <Spacing spacing="xxl" />
        <TextField
          {...emailInput.getProps({
            autoComplete: "username",
            "aria-label": "Email",
            type: "email",
            tabIndex: "0",
            disabled: isLoading,
          })}
        />
        <Spacing spacing="xxl" />
        <TextField
          {...newPasswordInput.getProps({
            autoComplete: "new-password",
            "aria-label": "New Password",
            tabIndex: "0",
            type: "password",
            placeholder: "Minimum 8 characters.",
            disabled: isLoading,
          })}
        />
        <Spacing spacing="xxl" />
        <TextField
          {...confirmPasswordInput.getProps({
            autoComplete: "new-password",
            "aria-label": "Confirm Password",
            tabIndex: "0",
            type: "password",
            disabled: isLoading,
          })}
        />
        <Spacing spacing="xxl-2" />
        <Button
          block
          tabIndex="0"
          type="submit"
          loading={isLoading}
          disabled={invalidInputs}
          onClick={onSubmitHandler}>
          Reset Password
        </Button>
      </form>
    </AuthLayout>
  )
}

export default ResetPasswordPage
